<template>
  <div v-click-outside="onBlur" class="flex flex-col">
    <div
      v-click-outside="onBlur"
      class="flex px-2 rounded-10 bg-white border border-gray-300 relative input"
      :class="{
        'cursor-text': !pointer,
        'cursor-pointer': pointer,
        'border-blue-200': focus && !errorShow && focused,
        'border-red-100': !!errorShow,
        'pointer-events-none': disabled,
      }"
      @click="inputFocus"
    >
      <span
        v-if="placeholder"
        class="placeholder-mask text-body3 select-none"
        :class="{
          focus: focus,
          filled: !!value || value === 0,
          'text-body4': focus || !!value || value === 0,
        }"
      >
        {{ placeholder }}
      </span>
      <div
        v-if="hasPrependSlot"
        class="w-8 flex justify-center items-center text-gray-400"
        :class="{
          'text-blue-200': focus,
          'text-red-100': !!errorShow,
        }"
      >
        <slot name="prepend" />
      </div>
      <div v-else class="ml-2" />
      <div class="flex-grow">
        <span
          class="placeholder text-body3 select-none whitespace-nowrap"
          :class="{
            focus: focus && focused,
            filled: !!value || value === 0,
            'text-body4': focus || !!value || value === 0,
            error: !!errorShow,
            prepend: hasPrependSlot,
          }"
        >
          {{ placeholder }}
        </span>
        <slot
          v-bind="{
            onFocus,
            onBlur,
            forwardRef: 'input',
          }"
        >
          <input
            ref="input"
            v-model="localValue"
            v-mask="mask"
            :readonly="readonly"
            class="w-full text-body3 py-2"
            :class="{
              'my-1': !small,
              'text-right': textRight,
            }"
            :type="type"
            @focus="onFocus"
            @keydown.tab="onBlur"
            @keyup.enter="$emit('enter')"
            @keyup.esc="onEsc"
            @blur="$emit('blur')"
          >
        </slot>
      </div>
      <div
        v-if="hasAppendSlot"
        class="w-8 my-2 flex justify-center items-center"
        :class="[$listeners.appendClick ? 'cursor-pointer' : '']"
        @click="onAppendClick"
      >
        <slot name="append" />
      </div>
      <div v-else class="ml-2" />
    </div>
    <div class="-mb-6">
      <div class="my-1 min-h-4">
        <TransitionExpand>
          <template v-if="errorShow && errorMessage">
            <span class="error-message text-body4">
              {{ errorMessage }}
            </span>
          </template>
        </TransitionExpand>
      </div>
    </div>
  </div>
</template>

<script>
import TransitionExpand from 'devotedcg-ui-components/animations/TransitionsExpand.vue';

export default {
  name: 'CInput',
  components: {
    TransitionExpand,
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    small: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },

    errorMessage: {
      type: String,
      default: '',
    },
    errorShow: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textRight: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: [Object, String],
      default: () => ({}),
    },
    hideZeroOnFocus: {
      type: Boolean,
      default: false,
    },
    pointer: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      focus: false,
      localValue: this.value,
    };
  },
  computed: {
    hasPrependSlot() {
      return !!this.$slots.prepend;
    },
    hasAppendSlot() {
      return !!this.$slots.append;
    },
    input() {
      let data = this.$refs;
      if (this.$scopedSlots.default) {
        data = this.$scopedSlots.default()[0].context.$refs;
      }
      return data.input;
    },
  },
  watch: {
    focus(focus) {
      this.$emit('focus-change', focus);

      if (this.hideZeroOnFocus && [0, ''].includes(this.value)) {
        this.localValue = focus ? '' : 0;
      }
    },

    localValue(val) {
      this.$emit('input', val);

      if (this.hideZeroOnFocus && val === 0 && this.focus) {
        this.localValue = '';
      }
    },

    value(val) {
      this.localValue = val;
    },
  },
  methods: {
    onEsc(event) {
      this.localValue = event.target.value;
    },

    onFocus() {
      this.focus = true;
    },

    onBlur() {
      this.focus = false;
      this.input.blur();
    },

    onAppendClick() {
      const caretAtStart = this.input.selectionStart;
      const caretAtEnd = this.input.selectionEnd;
      this.$emit('append-click');
      this.input.focus();
      setTimeout(() => {
        try {
          this.input.setSelectionRange(caretAtStart, caretAtEnd);
        } catch (error) {
          console.error('onAppendClick', error);
        }
      }, 1);
    },

    inputFocus() {
      const caretAtStart = this.input.selectionStart;
      const caretAtEnd = this.input.selectionEnd;
      this.input.focus();
      setTimeout(() => {
        try {
          this.input.setSelectionRange(caretAtStart, caretAtEnd);
        } catch (error) {
          console.error('inputFocus', error);
        }
      }, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
input,
textarea {
  @apply rounded-5;
  cursor: inherit;
  &:focus {
    outline: none;
  }
}

.error-message {
  @apply text-red-100;
}

.input {
  .placeholder {
    top: 50%;
    transform: translateX(-8px) translateY(-50%);
  }

  &-textarea {
    .placeholder {
      top: -2px;
      transform: translateX(-8px) translateY(18px);
    }
  }
}

.placeholder-mask {
  @apply bg-white;
  @apply text-white;
  position: absolute;
  top: 0;
  padding: 2px 8px 0;
  transform: scaleX(0) translateY(-50%);
  transition: transform 0.3s ease-in-out;
  transition-delay: 0.2s;
  pointer-events: none;
  height: 3px;
  overflow: hidden;

  &.focus,
  &.filled {
    transform: scaleX(1) translateY(-50%);
    transition: transform 0.2s ease-in-out;
    transition-delay: 0s;
  }
}

.placeholder {
  @apply text-gray-400;
  position: absolute;
  transition: top 0.3s ease-in-out;
  transition-property: top, font-size, transform;
  padding: 2px 8px 0;
  pointer-events: none;

  &.focus {
    @apply text-blue-200;
  }

  &.error {
    @apply text-red-100;
  }

  &.focus,
  &.filled {
    top: -2px;
    transform: translateX(-8px) translateY(-50%);
    transition-delay: 0.1s;
  }

  &.focus.prepend,
  &.filled.prepend {
    transform: translateX(-32px) translateY(-50%);
  }
}
</style>
