var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onBlur),expression:"onBlur"}],staticClass:"flex flex-col"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onBlur),expression:"onBlur"}],staticClass:"flex px-2 rounded-10 bg-white border border-gray-300 relative input",class:{
      'cursor-text': !_vm.pointer,
      'cursor-pointer': _vm.pointer,
      'border-blue-200': _vm.focus && !_vm.errorShow && _vm.focused,
      'border-red-100': !!_vm.errorShow,
      'pointer-events-none': _vm.disabled,
    },on:{"click":_vm.inputFocus}},[(_vm.placeholder)?_c('span',{staticClass:"placeholder-mask text-body3 select-none",class:{
        focus: _vm.focus,
        filled: !!_vm.value || _vm.value === 0,
        'text-body4': _vm.focus || !!_vm.value || _vm.value === 0,
      }},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),(_vm.hasPrependSlot)?_c('div',{staticClass:"w-8 flex justify-center items-center text-gray-400",class:{
        'text-blue-200': _vm.focus,
        'text-red-100': !!_vm.errorShow,
      }},[_vm._t("prepend")],2):_c('div',{staticClass:"ml-2"}),_c('div',{staticClass:"flex-grow"},[_c('span',{staticClass:"placeholder text-body3 select-none whitespace-nowrap",class:{
          focus: _vm.focus && _vm.focused,
          filled: !!_vm.value || _vm.value === 0,
          'text-body4': _vm.focus || !!_vm.value || _vm.value === 0,
          error: !!_vm.errorShow,
          prepend: _vm.hasPrependSlot,
        }},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]),_vm._t("default",function(){return [((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"},{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],ref:"input",staticClass:"w-full text-body3 py-2",class:{
            'my-1': !_vm.small,
            'text-right': _vm.textRight,
          },attrs:{"readonly":_vm.readonly,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"focus":_vm.onFocus,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.onBlur.apply(null, arguments)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.onEsc.apply(null, arguments)}],"blur":function($event){return _vm.$emit('blur')},"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"},{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],ref:"input",staticClass:"w-full text-body3 py-2",class:{
            'my-1': !_vm.small,
            'text-right': _vm.textRight,
          },attrs:{"readonly":_vm.readonly,"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"focus":_vm.onFocus,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.onBlur.apply(null, arguments)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.onEsc.apply(null, arguments)}],"blur":function($event){return _vm.$emit('blur')},"change":function($event){_vm.localValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"},{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],ref:"input",staticClass:"w-full text-body3 py-2",class:{
            'my-1': !_vm.small,
            'text-right': _vm.textRight,
          },attrs:{"readonly":_vm.readonly,"type":_vm.type},domProps:{"value":(_vm.localValue)},on:{"focus":_vm.onFocus,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.onBlur.apply(null, arguments)},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.onEsc.apply(null, arguments)}],"blur":function($event){return _vm.$emit('blur')},"input":function($event){if($event.target.composing){ return; }_vm.localValue=$event.target.value}}})]},null,{
          onFocus: _vm.onFocus,
          onBlur: _vm.onBlur,
          forwardRef: 'input',
        })],2),(_vm.hasAppendSlot)?_c('div',{staticClass:"w-8 my-2 flex justify-center items-center",class:[_vm.$listeners.appendClick ? 'cursor-pointer' : ''],on:{"click":_vm.onAppendClick}},[_vm._t("append")],2):_c('div',{staticClass:"ml-2"})]),_c('div',{staticClass:"-mb-6"},[_c('div',{staticClass:"my-1 min-h-4"},[_c('TransitionExpand',[(_vm.errorShow && _vm.errorMessage)?[_c('span',{staticClass:"error-message text-body4"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]:_vm._e()],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }